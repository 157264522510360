import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { device, getBackgroundColor } from "../components/GlobalStyles"
import Layout from "../components/layout/layout"
import SEO from "../components/SEO"
import Title from "../components/shared/Title/Title"
import { ContactFormContext } from "../store/contact-form-ref-context"

const Wrapper = styled.div<{ color: { bg: string; text: string } }>`
  display: grid;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  align-content: center;
  justify-items: center;
  padding: 50px 60px;
  background-color: ${(props) => props.color.bg};
  box-sizing: border-box;
  color: ${(props) => props.color.text};

  @media ${device.tablet} {
    padding: 80px 0;
  }

  .logo {
    width: 60px;

    @media ${device.tablet} {
      width: auto;
    }
  }
`

const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  max-width: 638px;
  justify-items: center;
`

const StyledTitle = styled(Title)`
  margin: 20px 0 50px 0;
`

const StyledSubtitle = styled(Title)`
  margin: 0;
`

interface SukcesPageInterface {
  data: {
    page: {
      frontmatter: {
        seo?: {
          title: string
          description: string
          article?: "true" | "false"
        }
        success: {
          title: string
          subtitle: string
          color: string
        }
      }
    }
  }
  location: Location
}

const SuccessPage = ({ data: { page }, location }: SukcesPageInterface) => {
  const contactFormSection = React.useRef(null)
  const { seo, success } = page.frontmatter
  return (
    <ContactFormContext.Provider
      value={{
        sectionRef: contactFormSection,
      }}
    >
      <Layout location={location}>
        <SEO
          title={seo?.title}
          description={seo?.description}
          article={seo?.article === "true"}
        />
        <Wrapper color={getBackgroundColor(success.color)}>
          <Content>
            <StaticImage
              src="./../images/logo-white.png"
              alt="logo"
              loading="eager"
              placeholder="none"
              objectFit="contain"
              width={115}
              className="logo"
            />
            <StyledTitle level="h2">{success.title}</StyledTitle>
            <StyledSubtitle level="h3">{success.subtitle}</StyledSubtitle>
          </Content>
        </Wrapper>
      </Layout>
    </ContactFormContext.Provider>
  )
}

export default SuccessPage

export const query = graphql`
  query {
    page: mdx(fields: { slug: { eq: "/success/" } }) {
      frontmatter {
        seo {
          ...SeoFragment
        }
        success {
          title
          subtitle
          color
        }
      }
    }
  }
`
